
export default [
    {
        path: '/admin',
        name: 'admin.index',
        redirect: {
            name: 'admin.submitted-nft'
        },
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/submitted-nft',
        name: 'admin.submitted-nft',
        component: () => import('../views/admin/SubmittedNft.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/all-nft',
        name: 'admin.all-nft',
        component: () => import('../views/admin/AllNft.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/top-nft',
        name: 'admin.top-nft',
        component: () => import('../views/admin/TopNft.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/giveaway',
        name: 'admin.giveaway',
        component: () => import('../views/admin/Giveaway.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/settings',
        name: 'admin.settings',
        component: () => import('../views/admin/Settings.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/feedback',
        name: 'admin.feedback',
        component: () => import('../views/admin/Feedback.vue'),
        meta: {
            auth: 'admin'
        }
    },
];
