<template>
    <i class="icon">
        <svg
            :class="{ black: $vuetify.theme.dark }"
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="45"
                height="45"
                rx="22.5"
                :fill="$vuetify.theme.dark ? 'black' : '#EAEBFC'"
            />
            <path
                d="M34.5006 16.1329C33.6565 16.4996 32.7635 16.7412 31.8497 16.8501C32.8101 16.2877 33.5323 15.3944 33.8809 14.3375C32.9729 14.8684 31.9812 15.2409 30.9483 15.4391C30.5133 14.9832 29.9902 14.6206 29.4107 14.3732C28.8312 14.1258 28.2075 13.9988 27.5774 14C25.0264 14 22.962 16.0344 22.962 18.5423C22.9602 18.8911 23.0002 19.2389 23.0811 19.5783C21.2518 19.4925 19.4606 19.0259 17.822 18.2082C16.1834 17.3905 14.7336 16.2398 13.5652 14.8297C13.1553 15.5207 12.9385 16.3091 12.9375 17.1126C12.9375 18.6876 13.7593 20.0799 15.0001 20.8955C14.2649 20.878 13.5448 20.6835 12.901 20.3283V20.3845C12.901 22.5877 14.4948 24.4206 16.6042 24.8378C16.2075 24.9435 15.7987 24.9971 15.3882 24.9972C15.0969 24.9977 14.8063 24.9694 14.5205 24.9128C15.107 26.7175 16.8137 28.0301 18.8355 28.0676C17.1927 29.3336 15.1757 30.018 13.1016 30.0129C12.7334 30.0124 12.3656 29.9905 12 29.9473C14.11 31.2946 16.5627 32.0073 19.0661 32.0005C27.5676 32.0005 32.2121 25.0769 32.2121 19.072C32.2121 18.8751 32.2069 18.6783 32.1975 18.4861C33.099 17.8448 33.8789 17.048 34.5006 16.1329Z"
                fill="url(#paint0_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="35"
                    y1="31"
                    x2="15"
                    y2="31"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#8239FF" />
                    <stop offset="1" stop-color="#17A5FF" />
                </linearGradient>
            </defs>
        </svg>
    </i>
</template>

<script>
export default {
    name: "TwitterIconHeader",
};
</script>

<style lang="scss" scoped>
.icon {
    cursor: pointer;
    display: flex;

    svg {
        border-radius: 50%;
    }

    & > .black:hover {
        rect {
            fill: url(#paint0_linear);
        }

        path {
            fill: black;
        }
    }
}
</style>
