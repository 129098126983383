<template>
    <router-link
        @click="$emit('click')"
        :href="href"
        :target="target"
        :to="{
            name: to ? to : '',
        }"
        class="
            button
            px-5
            py-1
            v-btn--active
            v-btn v-btn--is-elevated v-btn--has-bg v-btn--rounded v-btn--router
            theme--light
            v-size--small
        "
    >
        <div @click="$emit('click')">
            <slot></slot>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "GradientButton",
    props: {
        to: {
            required: false,
        },
        href: {
            required: false,
        },
        target: {
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.button {
    background: linear-gradient(270deg, #8238ff 11.11%, #12a9fe 100%);
    color: #ffffff;
}
</style>
