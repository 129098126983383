<template>
    <v-app>
        <Header />
        <v-main
            class="main"
            :class="{
                gradient: !$vuetify.theme.dark,
            }"
        >
            <v-container class="main--container">
                <slot> <!--CONTENT--> </slot>
            </v-container>
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import Footer from "./Footer.vue";
import Header from "./Header.vue";
export default {
    components: { Footer, Header },
    name: "PublicLayout",
};
</script>

<style lang="scss" scoped>
.main {
    &--container {
        height: 100%;
    }
    &.gradient {
        background: linear-gradient(
            180deg,
            #d8d2e3 0%,
            #faf9fd 30%,
            #faf9fd 100%
        );
    }
}
</style>
