<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>
<style type="text/css">
#nprogress .bar {
    height: 4px !important;
}
</style>
