export const store = (nftoken) => {
    return window.axios.post('/api/nftokens', nftoken);
}
export const update = (nftoken) => {
    return window.axios.put('/api/nftokens/' + nftoken.id, nftoken);
}
export const changeOrder = (params) => {
    return window.axios.get('/api/nftokens/change-order', { params });
}
export const show = (id) => {
    return window.axios.get('/api/nftokens/' + id);
}
export const index = (params) => {
    return window.axios.get('/api/nftokens', { params });
}
export const liveNft = (params) => {
    return window.axios.get('/api/nftokens/live', { params });
}
export const mintNft = (params) => {
    return window.axios.get('/api/nftokens/mint', { params });
}
export const all = (params) => {
    return window.axios.get('/api/nftokens/all', { params });
}
export const destroy = (id) => {
    return window.axios.delete('/api/nftokens/' + id);
}
