const diffBetween = (a, b) => {
    let diffMs =
        Math.abs(a - b) / 1000;

    const days = Math.floor(diffMs / 86400);
    diffMs -= days * 86400;

    const hours = Math.floor(diffMs / 3600) % 24;
    diffMs -= hours * 3600;

    const minutes = Math.floor(diffMs / 60) % 60;
    diffMs -= minutes * 60;

    return {
        days, hours, minutes
    }
}
export {
    diffBetween
}
