import { index, store, all, show, update, destroy, changeOrder, liveNft, mintNft } from '@/api/nftoken.js';
const state = () => ({
    item: null,
    defaultItem: {
        email: "",
        title: "",
        description: "",
        is_sale_on: false,
        sale_date: "",
        sale_price: "",
        sale_currency: "ETH",
        images: [
            { type: "big", error: null },
            { type: "small", error: null },
            { type: "small", error: null },
            { type: "small", error: null },
        ],
        total_supply: "",
        website: "",
        discord: "",
        twitter: "",
        opensea: "",
        type: "mint",
        order: 0,
        status: "",
    },
    items: [],
    allItems: [],
    pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
        links: []
    },
    errors: {},
    success: false,
    mintTokens: [],
    liveTokens: [],
    loading: false,
})

const getters = {

    success: state => {
        return state.success;
    },
    loading: state => {
        return state.loading;
    },
    item: state => {
        return state.item;
    },
    mintTokens: state => {
        return state.mintTokens;
    },
    liveTokens: state => {
        return state.liveTokens;
    },
    items: state => {
        return state.items;
    },
    allItems: state => {
        return state.allItems;
    },
    errors: state => {
        return state.errors;
    },
    pagination: state => {
        return state.pagination;
    },
    livePagination: state => {
        return state.livePagination;
    },
    mintPagination: state => {
        return state.mintPagination;
    },
}
const mutations = {

    setItem(state, item) {
        state.item = item;
    },
    setItems(state, items) {
        state.items = items;
    },
    setLiveTokens(state, tokens) {
        state.liveTokens = tokens;
    },
    setMintTokens(state, tokens) {
        state.mintTokens = tokens;
    },
    setGiveaway(state) {
        let from = state.liveTokens.findIndex(item => item.type == "giveaway")
        if (from > -1) {
            state.liveTokens[1] = state.liveTokens.splice(from, 1, state.liveTokens[1])[0];
        }
    },
    setAllItems(state, items) {
        state.allItems = items;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
    setPagination(state, pagination) {
        state.pagination = pagination;
    },
    setMintPagination(state, pagination) {
        state.mintPagination = pagination;
    },
    setLivePagination(state, pagination) {
        state.livePagination = pagination;
    },
}

const actions = {
    async setItems({ commit }, params) {
        commit('setPagination', null)
        let { data } = await index(params);
        commit('setItems', data.data)
        commit('setGiveaway')

        if (data.meta && data.meta.total > 0) {
            commit('setPagination', data.meta)
        }
    },
    async setLiveTokens({ commit }, params) {
        commit('setLivePagination', null)
        let { data } = await liveNft(params);
        commit('setLiveTokens', data.data)
        commit('setGiveaway')

        if (data.meta && data.meta.total > 0) {
            commit('setLivePagination', data.meta)
        }
    },
    async setMintTokens({ commit }, params) {
        commit('setMintPagination', null)
        let { data } = await mintNft(params);
        commit('setMintTokens', data.data)

        if (data.meta && data.meta.total > 0) {
            commit('setMintPagination', data.meta)
        }
    },

    async setItem({ commit }, id, params) {
        let { data } = await show(id, params);
        commit('setItem', data.data)
    },
    async setLoading({ state }, loading) {
        state.loading = loading
    },
    async updateItem({ state, commit }, params) {
        update(state.item, params).then(({ data }) => {

            commit('setErrors', {})
            commit('setItem', data.data);
            state.success = true;
        }).catch((error) => {
            commit('setErrors', error.response.data.errors)
            state.success = false;
        })

    },
    async setAllItems({ commit }, params) {
        commit('setPagination', null)
        let { data } = await all(params);
        commit('setAllItems', data.data)
        if (data.meta && data.meta.links.length > 0) {
            commit('setPagination', data.meta)
        }
    },
    async storeItem({ state, commit }, recaptchaToken) {

        store({ ...state.item, recaptcha_token: recaptchaToken }).then(() => {
            commit('setErrors', {})
            state.success = true;
            state.loading = false;
        }).catch((error) => {
            commit('setErrors', error.response.data.errors)
            state.success = false;
        })
    },
    async destroyItem({ state }) {
        await destroy(state.item.id)
    },
    async updateOrder(context, params) {
        await changeOrder(params)
    },

    setDefaultItem({ state, commit }) {
        commit('setItem', state.defaultItem)
    },
    setPagination({ state }) {
        let larger = state.livePagination.total > state.mintPagination.total ? state.livePagination : state.mintPagination
        state.pagination.current_page = larger.current_page
        state.pagination.last_page = larger.last_page
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
