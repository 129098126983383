<template>
    <i class="icon">
        <svg
            :class="{ black: $vuetify.theme.dark }"
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.158203"
                width="33.0427"
                height="33"
                rx="16.5"
                :fill="$vuetify.theme.dark ? 'black' : '#EFECFC'"
            />
            <path
                d="M26.3646 10.7651C25.6652 11.0686 24.9252 11.2685 24.168 11.3586C24.9637 10.8933 25.5622 10.1539 25.8511 9.27932C25.0987 9.71869 24.2769 10.027 23.421 10.191C23.0606 9.81371 22.6271 9.51359 22.1469 9.30886C21.6667 9.10412 21.1499 8.99904 20.6278 9.00001C18.5139 9.00001 16.8033 10.6836 16.8033 12.7591C16.8018 13.0478 16.8349 13.3356 16.9019 13.6164C15.3861 13.5454 13.9019 13.1593 12.5441 12.4826C11.1863 11.8059 9.98487 10.8536 9.01671 9.68665C8.67705 10.2585 8.49742 10.911 8.49659 11.5759C8.49659 12.8793 9.17752 14.0315 10.2057 14.7065C9.59653 14.692 8.99985 14.531 8.4663 14.2371V14.2836C8.4663 16.1069 9.78697 17.6237 11.5349 17.969C11.2062 18.0565 10.8675 18.1008 10.5273 18.1009C10.2859 18.1013 10.0451 18.0779 9.80834 18.0311C10.2943 19.5246 11.7086 20.6108 13.3839 20.6418C12.0226 21.6896 10.3512 22.2559 8.63255 22.2518C8.32748 22.2513 8.02269 22.2332 7.71973 22.1975C9.46814 23.3125 11.5005 23.9022 13.575 23.8966C20.6196 23.8966 24.4682 18.1668 24.4682 13.1974C24.4682 13.0345 24.464 12.8716 24.4562 12.7125C25.2032 12.1818 25.8494 11.5224 26.3646 10.7651Z"
                :fill="$vuetify.theme.dark ? '#846DFF' : '#4C54C3'"
            />
        </svg>
    </i>
</template>

<script>
export default {
    name: "TwitterIcon",
};
</script>

<style lang="scss" scoped>
.icon {
    cursor: pointer;
    display: flex;

    svg {
        border-radius: 50%;
    }

    & > .black:hover {
        rect {
            fill: #846dff;
        }

        path {
            fill: black;
        }
    }
    & > :hover {
        rect {
            fill: #4c54c3;
        }

        path {
            fill: white;
        }
    }
}
</style>
