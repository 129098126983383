<template>
    <i class="icon">
        <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="45"
                height="45"
                rx="22.5"
                :fill="$vuetify.theme.dark ? 'url(#paint0_linear)' : '#EAEBFC'"
            />
            <path
                d="M32.1493 22.9954C32.0443 22.9181 31.9209 22.8697 31.7914 22.855C31.6619 22.8402 31.5307 22.8596 31.411 22.9111C30.1706 23.4423 28.7995 23.5898 27.4745 23.3345C26.1495 23.0792 24.9314 22.4328 23.9772 21.4788C23.023 20.5247 22.3764 19.3067 22.1209 17.9817C21.8654 16.6568 22.0126 15.2857 22.5436 14.0452C22.5949 13.9253 22.6141 13.7942 22.5993 13.6647C22.5845 13.5352 22.5362 13.4118 22.4592 13.3066C22.3821 13.2015 22.279 13.1182 22.16 13.0651C22.0409 13.012 21.9101 12.9908 21.7804 13.0037C19.9437 13.1873 18.1969 13.8885 16.743 15.0257C15.2892 16.163 14.1879 17.6895 13.5674 19.4279C12.9469 21.1663 12.8326 23.0452 13.2378 24.846C13.643 26.6468 14.551 28.2956 15.8563 29.6007C17.1616 30.9058 18.8105 31.8136 20.6114 32.2185C22.4122 32.6235 24.2911 32.5089 26.0294 31.8882C27.7677 31.2674 29.2941 30.166 30.4312 28.712C31.5682 27.2579 32.2692 25.511 32.4525 23.6743C32.4654 23.5445 32.4442 23.4136 32.3911 23.2946C32.3379 23.1755 32.2545 23.0724 32.1493 22.9954Z"
                :fill="$vuetify.theme.dark ? 'black' : 'url(#paint0_linear)'"
            />

            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="45.9988"
                    y1="42.4988"
                    x2="5.99985"
                    y2="42.4988"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#8239FF" />
                    <stop offset="1" stop-color="#17A5FF" />
                </linearGradient>
            </defs>
        </svg>
    </i>
</template>

<script>
export default {
    name: "MoonIcon",
};
</script>

<style lang="scss" scoped>
.icon {
    cursor: pointer;
    display: flex;
}
</style>
