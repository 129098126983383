<template>
    <router-link :to="{ name: to }" class="dark--text link">
        <slot> </slot>
    </router-link>
</template>

<script>
export default {
    name: "CustomLink",
    props: {
        to: {
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    text-decoration: none;
    &:hover {
        color: #846dff !important;
    }
}
</style>
