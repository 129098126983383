<template>
    <div
        class="product-card primary pa-5 rounded-xl elevation-1"
        :class="{
            xs: $vuetify.breakpoint.xsOnly,
        }"
    >
        <div class="product-card--header">
            <h6 class="text-body-1 font-weight-bold text-wrap pa-0">
                {{ product.title }}
            </h6>
            <div
                class="d-flex flex-column align-end"
                v-if="product.type != 'giveaway'"
            >
                <div class="info--text text-caption d-flex align-center">
                    <v-icon small>mdi-clock-outline</v-icon>
                    <span class="ml-1">
                        {{
                            product.type == "mint" ? saleStart : "Sale is live"
                        }}
                    </span>
                </div>
                <div class="secondary--text text-caption font-weight-medium">
                    {{ product.total_supply }} total supply
                </div>
            </div>
        </div>
        <div
            class="giveaway text-body-2"
            v-if="product.type == 'giveaway'"
            :class="{ xs: $vuetify.breakpoint.xsOnly }"
        >
            <div v-if="bigImage" class="giveaway--image">
                <img
                    v-lazy="bigImage.url"
                    class="rounded"
                    height="200"
                    width="200"
                />
            </div>
            <div class="giveaway--button">
                <GradientButton
                    :href="'https://' + product.website.replace('www.', '')"
                    target="_blank"
                >
                    Participate now
                </GradientButton>
            </div>
            <p class="giveaway--description info--text text-caption">
                {{ product.description }}
            </p>
            <div class="giveaway--links">
                <v-btn
                    v-if="product.discord"
                    icon
                    :href="'https://' + product.discord.replace('www.', '')"
                    target="_blank"
                >
                    <DiscordIcon />
                </v-btn>
                <v-btn
                    v-if="product.twitter"
                    icon
                    :href="'https://twitter.com/' + product.twitter"
                    target="_blank"
                >
                    <TwitterIcon />
                </v-btn>
                <v-btn
                    v-if="product.opensea"
                    icon
                    :href="'https://' + product.opensea.replace('www.', '')"
                    target="_blank"
                >
                    <OpenSeaIcon />
                </v-btn>
            </div>
        </div>
        <div
            v-else
            class="product text-body-2"
            :class="{ xs: $vuetify.breakpoint.xsOnly }"
        >
            <div v-if="bigImage" class="product--big-image">
                <img
                    v-lazy="bigImage.url"
                    class="rounded"
                    height="200"
                    width="200"
                />
            </div>

            <div v-if="product.sale_price" class="product--price">
                <span class="info--text">{{
                    product.type == "mint" ? "Mint price" : "Sale price"
                }}</span>
                <img
                    class="mx-2"
                    :src="require('@/assets/icons/updown.svg')"
                    alt="price icon"
                />
                <span class="secondary--text font-weight-bold">{{
                    product.sale_price
                }}</span>
            </div>

            <div class="product--button">
                <GradientButton
                    :href="'https://' + product.website.replace('www.', '')"
                    target="_blank"
                >
                    Get yours now
                </GradientButton>
            </div>

            <span class="product--featured info--text text-caption"
                >Featured</span
            >

            <div class="product--small-images">
                <template v-for="(image, i) in smallImages">
                    <img
                        :key="i"
                        v-lazy="image.url"
                        class="rounded-lg"
                        height="100"
                        width="100"
                    />
                </template>
            </div>
            <p class="product--description info--text text-caption">
                {{ product.description }}
            </p>
            <div class="product--links">
                <v-btn
                    v-if="product.discord"
                    icon
                    :href="'https://' + product.discord.replace('www.', '')"
                    target="_blank"
                >
                    <DiscordIcon />
                </v-btn>
                <v-btn
                    v-if="product.twitter"
                    icon
                    :href="'https://twitter.com/' + product.twitter"
                    target="_blank"
                >
                    <TwitterIcon />
                </v-btn>
                <v-btn
                    v-if="product.opensea"
                    icon
                    :href="'https://' + product.opensea.replace('www.', '')"
                    target="_blank"
                >
                    <OpenSeaIcon />
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { diffBetween } from "@/utils/date";
export default {
    name: "ProductCard",
    props: {
        product: {
            required: true,
        },
    },
    data() {
        return {
            saleStart: "",
        };
    },
    computed: {
        bigImage() {
            return this.product.images.find((image) => image.type == "big");
        },
        smallImages() {
            let images = this.product.images.filter(
                (image) => image.type == "small"
            );

            return images;
        },
    },
    methods: {
        setSaleStart() {
            let productDate = new Date(this.product.sale_date);
            let date = new Date();

            if (productDate > date) {
                let { days, hours, minutes } = diffBetween(productDate, date);
                this.saleStart = `Sale starts in ${days} days ${hours} h ${minutes} min`;
            } else {
                this.saleStart = "Sale is started";
            }
        },
    },
    created() {
        setInterval(this.setSaleStart, 1000);
    },
};
</script>

<style lang="scss" scoped>
.product-card {
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-gap: 10px;

    &--header {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        justify-content: space-between;
        align-items: center;
    }

    &.xs {
        height: max-content;

        .product-card {
            &--header {
                height: max-content;
            }
        }
    }
}
.giveaway {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(3, max-content);
    align-content: center;
    grid-gap: 10px;
    align-items: center;
    &--image {
        grid-area: 1/1/3/2;
        align-self: center;
        display: grid;
        align-content: end;

        img {
            display: block;
            width: 100%;
            min-width: 200px;
            max-height: 200px;
            object-fit: cover;
        }
    }
    &--button {
        grid-area: 1/2/2/3;
        align-self: end;

        width: max-content;
        height: max-content;
    }
    &--description {
        grid-area: 2/2/3/3;
        align-self: start;
    }

    &--links {
        grid-area: 3/1/4/2;
        align-self: start;
        display: grid;
        grid-auto-flow: column;
        justify-self: start;
        grid-gap: 5px;
    }

    &.xs {
        grid-template-columns: auto;
        grid-template-rows: repeat(4, max-content);
        .giveaway {
            &--image {
                grid-area: 1/1/2/2;
            }
            &--button {
                grid-area: 2/1/3/2;
                justify-self: end;
                align-items: center;
                margin: 5px 0;
            }
            &--description {
                grid-area: 3/1/4/2;
            }
            &--links {
                grid-area: 4/1/5/2;
            }
        }
    }
}
.product {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(4, max-content);
    grid-gap: 10px;
    align-content: center;

    &--big-image {
        grid-area: 1/1/4/3;
        display: grid;
        align-content: end;
        img {
            display: block;
            width: 100%;
            min-width: 200px;
            max-height: 200px;
            object-fit: cover;
        }
    }
    &--small-images {
        grid-area: 3/3/4/5;
        display: grid;
        grid-gap: 10px;
        grid-auto-flow: column;

        img {
            align-self: end;
            display: block;
            width: 100%;
            max-height: 100px;
            object-fit: cover;
        }
    }

    &--price {
        grid-area: 1/3/2/5;
        align-self: end;
        justify-self: start;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 2px;
        align-items: center;
    }
    &--button {
        grid-area: 2/3/3/4;
        width: max-content;
        height: max-content;
    }
    &--featured {
        grid-area: 2/4/3/5;
        place-self: end;
    }

    &--description {
        grid-area: 4/2/5/5;
    }
    &--links {
        grid-area: 4/1/5/2;
        display: grid;
        grid-auto-flow: column;
        justify-self: start;
        grid-gap: 5px;
    }

    &.xs {
        .product {
            height: max-content;
            grid-gap: 5px;

            &--big-image {
                grid-area: 1/1/2/5;
                height: max-content;
                img {
                    width: 100%;
                }
            }

            &--small-images {
                grid-area: 2/1/3/5;
                height: max-content;
            }

            &--featured {
                grid-area: 3/4/4/5;
                height: max-content;
            }

            &--price {
                grid-area: 4/1/5/3;
                height: max-content;
            }
            &--button {
                grid-area: 4/3/5/5;
                justify-self: end;
                height: max-content;
            }

            &--description {
                grid-area: 5/1/6/5;
                height: max-content;
            }
            &--links {
                grid-area: 6/1/7/5;
                height: max-content;
            }
        }
    }
}
</style>
