<template>
    <v-footer padless color="primary">
        <v-container class="d-flex flex-row align-center flex-wrap py-6">
            <a class="logo d-flex align-center" href="/">
                <img :src="require('@/assets/icons/logo.svg')" alt="logo" />
                <span class="font-weight-bold text-body dark--text"
                    >nft<span class="secondary--text">is</span>magic</span
                >
            </a>
            <v-spacer></v-spacer>
            <v-row align="center" justify="end" class="text-body-2">
                <CustomLink to="contact-us"> Contact us </CustomLink>

                <CustomLink to="about" class="mx-5"> About </CustomLink>

                <GradientButton to="submit-nft"> add your NFT </GradientButton>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style lang="scss" scoped>
</style>
