<template>
    <v-text-field
        v-model="keyword"
        outlined
        clearable
        label="Search"
        type="text"
        color="dark"
        rounded
        dense
        :filled="$vuetify.theme.dark"
        append-outer-icon="mdi-magnify"
        @click:append-outer="search"
        @keydown.enter="search"
        @click:clear="clearSearch"
        hide-details
    >
        <template v-slot:append>
            <v-fade-transition leave-absolute>
                <v-progress-circular
                    v-if="loading"
                    size="24"
                    color="info"
                    indeterminate
                ></v-progress-circular>
            </v-fade-transition>
        </template>
    </v-text-field>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "SearchInput",
    data: () => ({
        keyword: "",
        loading: false,
    }),
    methods: {
        ...mapActions({
            setLiveTokens: "nftoken/setLiveTokens",
            setMintTokens: "nftoken/setMintTokens",
            setPagination: "nftoken/setPagination",
        }),
        async search() {
            this.loading = true;
            Promise.all([
                this.setMintTokens({ keyword: this.keyword }),
                this.setLiveTokens({ keyword: this.keyword }),
            ]);
            this.loading = false;
        },
        async clearSearch() {
            this.loading = true;
            Promise.all([this.setMintTokens(), this.setLiveTokens()]);
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
