<template>
    <i class="icon">
        <svg
            :class="{ black: $vuetify.theme.dark }"
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="33.0426"
                height="33"
                rx="16.5"
                :fill="$vuetify.theme.dark ? 'black' : '#EFECFC'"
            />
            <path
                d="M21.1115 23.1302C21.1115 23.1302 20.5092 22.4122 20.0074 21.7775C22.1974 21.1599 23.034 19.7901 23.034 19.7901C22.4325 20.1899 21.7876 20.5204 21.1115 20.7752C20.3333 21.1067 19.5195 21.3476 18.6861 21.4932C17.2544 21.7567 15.7861 21.7513 14.3564 21.4772C13.5167 21.3131 12.6937 21.0728 11.8978 20.7592C11.4783 20.5969 11.0705 20.4062 10.677 20.1885C10.6267 20.1554 10.5776 20.1382 10.5273 20.1051C10.5017 20.0931 10.4787 20.076 10.4599 20.0549C10.1593 19.8882 9.99238 19.7718 9.99238 19.7718C9.99238 19.7718 10.7925 21.1074 12.9185 21.7421C12.4167 22.3768 11.7972 23.1279 11.7972 23.1279C8.10185 23.0137 6.69824 20.5891 6.69824 20.5891C6.69824 15.2125 9.10541 10.8542 9.10541 10.8542C11.5137 9.05057 13.8043 9.10079 13.8043 9.10079L13.9712 9.30056C10.9616 10.1715 9.57404 11.4912 9.57404 11.4912C9.57404 11.4912 9.94209 11.2902 10.5605 11.0071C12.3493 10.2218 13.7711 10.0049 14.3564 9.95466C14.4497 9.93513 14.5445 9.92405 14.6398 9.92155C15.7603 9.77548 16.8942 9.76399 18.0174 9.88731C19.7821 10.0876 21.4905 10.6305 23.0466 11.4854C23.0466 11.4854 21.7265 10.2298 18.8838 9.36449L19.1124 9.09737C19.1124 9.09737 21.3984 9.04714 23.8101 10.8508C23.8101 10.8508 26.2185 15.2091 26.2185 20.5857C26.2276 20.5914 24.8057 23.0103 21.1115 23.1302ZM13.3391 15.3313C12.3858 15.3313 11.6337 16.1669 11.6337 17.1851C11.6337 18.2033 12.403 19.0389 13.3391 19.0389C14.2924 19.0389 15.0445 18.2033 15.0445 17.1851C15.0616 16.1669 14.2924 15.3313 13.3391 15.3313ZM19.4427 15.3313C18.4895 15.3313 17.7374 16.1669 17.7374 17.1851C17.7374 18.2033 18.5055 19.0389 19.4427 19.0389C20.38 19.0389 21.1481 18.2033 21.1481 17.1851C21.1481 16.1669 20.3949 15.3313 19.4427 15.3313Z"
                :fill="$vuetify.theme.dark ? '#846DFF' : '#4C54C3'"
            />
        </svg>
    </i>
</template>

<script>
export default {
    name: "DiscordIcon",
};
</script>

<style lang="scss" scoped>
.icon {
    cursor: pointer;
    display: flex;

    svg {
        border-radius: 50%;
    }

    & > .black:hover {
        rect {
            fill: #846dff;
        }

        path {
            fill: black;
        }
    }
    & > :hover {
        rect {
            fill: #4c54c3;
        }

        path {
            fill: white;
        }
    }
}
</style>
