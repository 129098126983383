import { index, store } from '@/api/feedback.js';
const state = () => ({
    item: null,
    defaultItem: {
        email: "",
        name: "",
        message: "",
    },
    items: [],
    errors: {},
    pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
        links: []
    },
    success: false,
})

const getters = {
    success: state => {
        return state.success;
    },
    item: state => {
        return state.item;
    },
    items: state => {
        return state.items;
    },

    errors: state => {
        return state.errors;
    },
    pagination: state => {
        return state.pagination;
    },
}


const mutations = {
    setItem(state, item) {
        state.item = item;
    },
    setItems(state, items) {
        state.items = items;
    },
    setErrors(state, errors) {
        state.errors = errors;
    },
    setPagination(state, pagination) {
        state.pagination = pagination;
    },
}

const actions = {
    async setItems({ commit }, params) {
        commit('setPagination', null)
        let { data } = await index(params);
        commit('setItems', data.data)

        if (data.meta && data.meta.total > 0) {
            commit('setPagination', data.meta)
        }
    },

    async storeItem({ state, commit }, recaptchaToken) {
        store({ ...state.item, recaptcha_token: recaptchaToken }).then(() => {
            commit('setErrors', {})
            state.success = true;
        }).catch((error) => {
            commit('setErrors', error.response.data.errors)
            state.success = false;
        })
    },
    setDefaultItem({ state, commit }) {
        commit('setItem', state.defaultItem)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
