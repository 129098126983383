<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th
                        v-for="(header, i) in headers"
                        :key="i"
                        class="text-left"
                    >
                        {{ header.text }}
                    </th>
                    <th v-if="Object.keys($listeners).length !== 0" class="text-left">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in items" :key="i">
                    <td v-for="(header, i) in headers" :key="i">
                        {{ item[header.value] }}
                    </td>
                    <td>
                        <v-row>
                            <v-btn
                                small
                                v-if="$listeners.up"
                                icon
                                @click="$emit('up', i)"
                            >
                                <v-icon small>mdi-arrow-up-thick</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                v-if="$listeners.down"
                                icon
                                @click="$emit('down', i)"
                            >
                                <v-icon small>mdi-arrow-down-thick</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                v-if="$listeners.edit"
                                icon
                                @click="$emit('edit', i)"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                v-if="$listeners.destroy"
                                icon
                                @click="$emit('destroy', i)"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                v-if="$listeners.remove"
                                icon
                                @click="$emit('remove', i)"
                            >
                                <v-icon small>mdi-minus-circle</v-icon>
                            </v-btn>
                        </v-row>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    name: "CustomTable",
    props: {
        headers: {
            required: true,
        },
        items: {
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
