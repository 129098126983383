export const storeFile = (file) => {
    return window.axios.post('/api/files', file);
}
export const destroyFile = (path) => {
    return window.axios.post('/api/files/destroy', { path });
}
export const getSettings = () => {
    return window.axios.get('/api/settings/1');
}
export const updateSettings = (settings) => {
    return window.axios.put('/api/settings/1', settings);
}
