import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#FFFFFF',
                secondary: '#5B43E0',
                lightGrey: '#E5E5E5',
                grey: '#EAEBFC',
                dark: '#000000',
                black: '#000000',
                accent: '#846DFF',
                error: '#FF5252',
                info: '#999999',
                success: '#4CAF50',
                warning: '#FFC107'
            },
            dark: {
                primary: '#1B1A1A',
                secondary: '#5B43E0',
                lightGrey: '#E5E5E5',
                grey: '#EAEBFC',
                dark: '#FFFFFF',
                black: '#000000',
                accent: '#846DFF',
                error: '#FF5252',
                info: '#999999',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
});
