import { storeFile, destroyFile, getSettings, updateSettings as updateSettingsRequest } from '@/api/site.js';
const state = {
    settings: {
        about: ""
    },
    recaptchaToken: "6LcM4KccAAAAAK5TS3Vqyf21PQpEZmbi3iFB7cZ0"
};

const getters = {
    settings: state => {
        return state.settings;
    },
    recaptchaToken: state => {
        return state.recaptchaToken;
    },
};

const mutations = {
    setSettings(state, settings) {
        state.settings = settings;
    },
};

const actions = {
    async uploadImage(context, file) {

        let form = new FormData();
        form.append("file", file);
        form.append("directory", "images");
        return storeFile(form);
    },
    async destroyImage(context, path) {
        await destroyFile(path)
    },
    async setSettings({ commit }) {
        let { data } = await getSettings();
        commit('setSettings', data.data);
    },
    async updateSettings({ state, commit }) {
        let { data } = await updateSettingsRequest(state.settings);
        commit('setSettings', data.data);
    },

};

export default {
    state, getters, mutations, actions
}
