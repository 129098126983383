import Home from "@/views/Home";

export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {

        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About.vue'),
        meta: {}
    },
    {
        path: '/contactus',
        name: 'contact-us',
        component: () => import('@/views/ContactUs.vue'),
        meta: {}
    },
    {
        path: '/submitnft',
        name: 'submit-nft',
        component: () => import('@/views/SubmitNftoken.vue'),
        meta: {}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {}
    },

    {
        path: '/403',
        name: '403',
        component: () => import('@/views/static/403.vue'),
        meta: {}
    },
    {
        path: '/404',
        name: 'error-not-found',
        component: () => import('@/views/static/404.vue'),
        meta: {}
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/static/PrivacyPolicy.vue'),
        meta: {}
    },
]
