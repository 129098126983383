<template>
    <div>
        <span class="font-weight-bold dark--text">{{ label }}</span>
        <div class="number-input text-field">
            <div class="price">
                <v-text-field
                    type="number"
                    class="input"
                    dense
                    outlined
                    color="dark"
                    :filled="$vuetify.theme.dark"
                    :required="required"
                    :error-messages="error"
                    :rules="rules"
                    min="0"
                    v-model="price"
                    @input="$emit('input', price)"
                    @change="handleChange"
                >
                </v-text-field>
            </div>
            <div class="currency">
                <v-select
                    color="dark"
                    class="text-caption pa-0 input"
                    eager
                    dense
                    outlined
                    append-icon=""
                    :items="currencies"
                    v-model="selectedCurrency"
                    @change="handleChange"
                    item-value="value"
                    item-text="value"
                ></v-select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PriceInput",
    props: {
        label: { required: false },
        value: { required: true },
        error: { required: false },
        rules: { required: false },
        currency: { required: true },
        required: { default: false },
    },
    data() {
        return {
            price: this.value,
            selectedCurrency: this.currency,
        };
    },
    watch: {
        value() {
            this.price = this.value;
        },
    },
    methods: {
        handleChange() {
            this.$emit("setCurrency", this.selectedCurrency);
            this.$emit("change");
        },
    },
    created() {
        this.currencies = [
            { value: "ETH" },
            { value: "BNB" },
            { value: "CAKE" },
            { value: "SOL" },
            { value: "SUSHI" },
        ];
    },
};
</script>

<style lang="scss" scoped>
.number-input {
    display: grid;

    grid-template-columns: 1fr 65px;
    align-items: start;

    .price {
        .input {
            border-radius: 5px 0px 0px 5px !important;
        }
    }

    .currency {
        .input {
            border-radius: 0px 5px 5px 0px !important;
        }
    }
}
</style>
