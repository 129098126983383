<template>
    <header class="header primary" :class="$vuetify.breakpoint">
        <a class="logo d-flex align-center" href="/">
            <img :src="require('@/assets/icons/logo.svg')" alt="logo" />
            <span class="font-weight-bold text-body dark--text"
                >nft<span class="secondary--text">is</span>magic</span
            >
        </a>

        <SearchInput v-if="$route.name == 'home'" class="search" />

        <nav class="nav text-body-2">
            <CustomLink to="contact-us"> Contact us </CustomLink>

            <CustomLink to="about" class=""> About </CustomLink>

            <GradientButton to="submit-nft"> add your NFT </GradientButton>
        </nav>
        <div class="buttons">
            <v-btn icon @click="toggleTheme"> <MoonIcon /> </v-btn>

            <v-btn icon href="https://twitter.com/NFTismagic" target="_blank">
                <TwitterIconHeader />
            </v-btn>
        </div>
    </header>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
export default {
    name: "Header",
    props: {
        isDashboard: {
            default: false,
            type: Boolean,
        },
    },
    computed: {},
    components: {
        SearchInput,
    },
    data() {
        return {
            drawer: false,
        };
    },
    methods: {
        toggleTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem("dark", this.$vuetify.theme.dark);
        },
    },
    created() {
        this.$vuetify.theme.dark = localStorage.getItem("dark") === "true";
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr repeat(3, auto);
    grid-gap: 15px;
    align-items: center;

    .nav {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 15px;
    }
    .buttons {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 15px;
    }

    &.xs {
        grid-template-columns: 1fr auto;
        .logo {
            grid-area: 1/1/2/2;
        }
        .buttons {
            grid-area: 1/2/2/3;
        }
        .nav {
            grid-area: 2/1/3/3;
        }
        .search {
            grid-area: 3/1/4/3;
        }
    }
}
</style>
