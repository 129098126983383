<template>
    <PublicLayout>
        <div
            class="home"
            :class="{
                xs: $vuetify.breakpoint.xsOnly,
                sm: $vuetify.breakpoint.smOnly,
            }"
        >
            <div class="home--title py-8">
                <h4 class="text-md-h4 font-weight-black p-0 mb-3">
                    NFT giveaways
                </h4>
                <p class="text-md-body info--text">
                    Best and newest collections of NFT on sale <br />
                    Grab yours now and HODL to it! WAGMI
                </p>
            </div>
            <div class="home--sorting">
                <v-btn text class="text-caption pa-0" href="#mint">
                    <div class="button">
                        <span class="secondary--text"
                            >Best NFT to be minted</span
                        >
                        <v-icon size="16" color="secondary">
                            mdi-arrow-down-thin-circle-outline
                        </v-icon>
                    </div>
                </v-btn>

                <v-btn text class="text-caption pa-0" href="#live">
                    <div class="button">
                        <span class="secondary--text">Top NFT to buy now</span>
                        <v-icon size="16" color="secondary"
                            >mdi-arrow-down-thin-circle-outline</v-icon
                        >
                    </div>
                </v-btn>
            </div>
            <div class="home--products">
                <div id="mint">
                    <ProductCard
                        v-for="(nftoken, i) in mintTokens"
                        :key="i"
                        :product="nftoken"
                    />
                </div>
                <div id="live">
                    <ProductCard
                        v-for="(nftoken, i) in liveTokens"
                        :key="i"
                        :product="nftoken"
                    />
                </div>
            </div>
            <div class="home--pagination" v-if="pag">
                <v-btn
                    @click="handlePage(pag.current_page - 1)"
                    outlined
                    rounded
                    :disabled="pag.current_page == 1"
                    color="secondary"
                >
                    {{ prevPage }}
                </v-btn>

                <v-btn
                    :disabled="pag.current_page == pag.last_page"
                    @click="handlePage(pag.current_page + 1)"
                    rounded
                    outlined
                    color="secondary"
                >
                    {{ nextPage }}
                </v-btn>
            </div>
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from "@/layouts/public/Index.vue";
import ProductCard from "@/components/ProductCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Home",
    components: {
        PublicLayout,
        ProductCard,
    },
    data() {
        return {
            sortType: "",
        };
    },
    computed: {
        ...mapGetters({
            mintTokens: "nftoken/mintTokens",
            liveTokens: "nftoken/liveTokens",
            pag: "nftoken/pagination",
        }),
        prevPage() {
            let text = "prev";
            // if (this.pag) {
            //     let { current_page, per_page, from, to } = this.pag;
            //     if (current_page == 1) {
            //         text = from + " - " + to;
            //     } else {
            //         text = from - per_page + " - " + (from - 1);
            //     }
            // }
            return text;
        },
        nextPage() {
            let text = "next";
            // if (this.pag) {
            //     let { total, current_page, per_page, from, last_page, to } =
            //         this.pag;

            //     if (current_page < last_page - 1) {
            //         text = to + 1 + " - " + (current_page + 1) * per_page;
            //     } else if (current_page == last_page - 1) {
            //         text = to + 1 + " - " + total;
            //     } else {
            //         text = from + " - " + total;
            //     }
            // }
            return text;
        },
    },
    methods: {
        ...mapActions({
            setMintTokens: "nftoken/setMintTokens",
            setLiveTokens: "nftoken/setLiveTokens",
            setGiveaway: "nftoken/setGiveaway",
            setPagination: "nftoken/setPagination",
        }),
        handleSort(type) {
            if (this.sortType == type) {
                this.sortType = "";
                this.setTokens({});
            } else {
                this.sortType = type;
                this.setTokens({ type });
            }
        },
        handlePage(page) {
            Promise.all([
                this.setMintTokens({
                    page: page,
                }),
                this.setLiveTokens({
                    page: page,
                }),
            ]).then(() => {
                this.setPagination();
            });
        },
    },
    created() {
        Promise.all([this.setMintTokens(), this.setLiveTokens()]).then(() => {
            this.setPagination();
        });
    },
};
</script>
<style lang="scss" scoped>
.home {
    &--title {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 15px;
        justify-items: center;
    }

    &--sorting {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-items: start;
        .button {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: auto 1fr;
            grid-gap: 10px;
            align-items: center;
            justify-items: start;
            padding: 15px;
        }
    }

    &--products {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        & > div {
            display: grid;
            grid-gap: 20px;
            grid-template-rows: repeat(3, 1fr);
        }
    }

    &--pagination {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        grid-gap: 20px;
        padding: 20px;
    }

    &.xs,
    &.sm {
        .home {
            &--products {
                grid-template-columns: auto;
                grid-gap: 10px;

                & > div {
                    grid-template-rows: repeat(3, auto);
                }
            }

            &--sorting {
                .button {
                    padding: 5px;
                    grid-gap: 5px;
                    justify-content: space-between;
                }
            }
        }
    }
}
</style>
