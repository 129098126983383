<template>
    <div class="file-input pa-1" :class="{ 'dark-theme': $vuetify.theme.dark }">
        <input
            :id="label"
            type="file"
            accept="image/png,image/jpeg"
            @change="handleUpload($event.target.files[0])"
        />
        <div v-if="loading" class="d-flex align-center justify-center">
            <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
            ></v-progress-circular>
        </div>
        <div v-else-if="url" class="preview">
            <v-btn fab dark x-small color="red" class="button" @click="destroy">
                <v-icon>mdi-delete</v-icon>
            </v-btn>

            <img :src="url" />
        </div>
        <span
            class="d-block text-truncate text-center"
            style="width: 99%"
            v-if="fileName"
        >
            {{ fileName }}
        </span>
        <span class="text-caption" v-else>{{ label }}</span>

        <label
            :for="label"
            class="
                button
                v-btn--rounded
                secondary--text
                text-no-wrap
                px-3
                py-1
                mt-2
            "
        >
            Select file
        </label>
        <div v-if="error" class="text-caption error--text">
            {{ error }}
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    components: {},
    name: "CustomFileUpload",
    props: {
        value: {
            required: true,
        },
        label: {
            required: true,
        },
        error: {
            required: false,
        },
    },
    data() {
        return {
            fileName: null,
            url: this.value.url,
            path: this.value.path,
            loading: false,
        };
    },
    watch: {
        value(val) {
            this.url = val.url;
            this.path = val.path;
        },
    },
    methods: {
        ...mapActions({
            uploadImage: "uploadImage",
            destroyImage: "destroyImage",
        }),
        async destroy() {
            this.url = null;
            this.fileName = null;
            this.destroyImage(this.path);
        },
        async handleUpload(file) {
            if (file) {
                if (file.size > 2097152) {
                    this.$emit("input", {
                        type: this.value.type,
                        error: "max file size 2 mb",
                    });
                    return;
                }
                this.loading = true;
                this.url = URL.createObjectURL(file);
                this.fileName = file.name;
                let { data } = await this.uploadImage(file);
                this.path = data.path;
                this.loading = false;
                this.$emit("input", {
                    id: this.value.id,
                    type: this.value.type,
                    ...data,
                });
                this.$emit("change");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.file-input {
    input[type="file"] {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    background: #f7f6fb;
    border: 1px solid rgba(149, 159, 230, 0.3);
    box-sizing: border-box;
    border-radius: 8px;

    padding: 18px 0;

    &.dark-theme {
        background: #2a2929;
        border: 1px solid rgba(234, 234, 234, 0.15);
    }

    display: grid;
    place-items: center;

    .preview {
        position: relative;
        .button {
            position: absolute;
            top: 5px;
            right: 5px;
        }
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 150px;
        }
    }

    &:focus-within {
        .button {
            color: #ffffff !important;
            background: linear-gradient(270deg, #8238ff 11.11%, #12a9fe 100%);
        }
    }

    .button {
        color: #ffffff;
        cursor: pointer;

        background: linear-gradient(
            270deg,
            rgba(130, 56, 255, 0.2) 11.11%,
            rgba(18, 169, 254, 0.2) 100%
        );

        &:hover {
            color: #ffffff !important;
            background: linear-gradient(270deg, #8238ff 11.11%, #12a9fe 100%);
        }
    }
}
</style>
